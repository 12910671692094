import React, { Component } from 'react';
// import Tilt from 'react-tilt';
import Particles from 'react-particles-js';
// const Clarifai = require('clarifai');
// import Clarifai from 'clarifai'; // moved to backend
import Navigation from './components/Navigation/Navigation';
import Signin from './components/Signin/Signin';
import Register from './components/Register/Register';
import FaceRecognition from './components/FaceRecognition/FaceRecognition';
import Logo from './components/Logo/Logo';
import ImageLinkForm from './components/ImageLinkForm/ImageLinkForm';
import Rank from './components/Rank/Rank';
import './App.css';

// moved to backend
/*const app = new Clarifai.App({
 apiKey: 'afec2cce8e6c4aa1aee77f297fe06b70'
});*/

const particlesStuff = {
  particles: {
    number: {
      value: 150,
      density: {
        enable: true,
        value_area: 800
      }
    }
  },
  "interactivity": {
    "events": {
      "onhover": {
        "enable": true,
        "mode": "repulse"
      },
      "onclick": {
        "enable": true,
        "mode": "push"
      },
      "resize": true
    },
    "modes": {
      "grab": {
        "distance": 400,
        "line_linked": {
          "opacity": 1
        }
      },
      "bubble": {
        "distance": 400,
        "size": 40,
        "duration": 2,
        "opacity": 8,
        "speed": 3
      },
      "repulse": {
        "distance": 200,
        "duration": 0.4
      },
      "push": {
        "particles_nb": 4
      },
      "remove": {
        "particles_nb": 2
      }
    }
  }
}

const initialState = {
  input: '',
  imageURL: '',
  box: {},
  route: 'signin',
  isSignedIn: false,
  user: {
    id: '',
    name: '',
    email: '',
    entries: 0,
    joined: ''
  }
}

class App extends Component {
  constructor() {
    super();
    this.state = initialState;
  }

  /*componentDidMount() {
    fetch('http://localhost:3000/')
    .then(res => res.json())
    .then(console.log)
  }*/

  loadUser = data => {
    this.setState({user: {
      id: data.id,
      name: data.name,
      email: data.email,
      entries: data.entries,
      joined: data.joined
    }})
  }

  calculateFaceLocation = (data) => {
    const clariFace = data.outputs[0].data.regions[0].region_info.bounding_box;
    // console.log(clariFace);
    const image = document.getElementById('inputimage');
    // const width = +image.width;
    const width = Number(image.width);
    const height = Number(image.height);
    // console.log(width, height);
    return {
      leftCol: clariFace.left_col * width,
      topRow: clariFace.top_row * height,
      rightCol: width - (clariFace.right_col * width),
      bottomRow: height - (clariFace.bottom_row * height),
    }
  }

  displayBox = (x) => {
    console.log(x);
    this.setState({box: x});
  }

  onInputChange = (event) => {
    // console.log(event.target.value);
    this.setState({input: event.target.value}); 
  }

  onButtonSubmit = () => {
    this.setState({imageURL: this.state.input});
    // app.models.predict(Clarifai.FACE_DETECT_MODEL, 
    //   this.state.input)
    //'http://localhost:3000/imageurl'
    fetch('https://smart-brain-back-end.herokuapp.com/imageurl', {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        input: this.state.input
      })
    }).then(res => res.json())
    .then(response => {
        if(response) {
          fetch('https://smart-brain-back-end.herokuapp.com/image', {
            method: 'put',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
              id: this.state.user.id
            })
          })
          .then(res => res.json())
          .then(count => { this.setState(Object.assign(this.state.user, {
            entries: count
          }))})
          .catch(console.log)
        }
        this.displayBox(this.calculateFaceLocation(response))
      })
      .catch(err => console.log(err));
        // console.log(response.outputs[0].data.regions[0].region_info.bounding_box);
      // ,
      // function(err) {
      //   // there was an error
      //   console.log("danger",err);
      // }
  }

  onRouteChange = (rote) => {
    if(rote === 'signout') 
      this.setState(initialState)
    else if (rote === 'home') 
      this.setState({isSignedIn: true})
    
    this.setState({route: rote})
  }

  render() {
    const { isSignedIn, imageURL, route, box } = this.state,
     { name, entries} = this.state.user ;

    return (
      <div className="App">
        <Particles className='particles'
          params={particlesStuff}
        /*  style={{
            width: '100%',
            backgroundImage: `url(${logo})` 
          }}*/
        />
        <Navigation isSignedIn={isSignedIn} onRouteChange={this.onRouteChange} />
        { route === 'home' 
            ? <div>
                <Logo />
                <Rank name={name} entry = {entries}/>
                <ImageLinkForm onInputChange={this.onInputChange} onButtonSubmit={this.onButtonSubmit} />
                <FaceRecognition box={box} imageURL={imageURL} />
              </div> 
            : (
                route === 'signin' 
                ? <Signin loadUser={this.loadUser} onRouteChange={this.onRouteChange}/> 
                : <Register loadUser={this.loadUser} onRouteChange={this.onRouteChange}/>
              ) 
      }
      </div>
    );
  }
}

export default App;
