import React from 'react';
import './Rank.css';

const Rank = ({ name, entry }) => {
  return (
    <div>
      <div className='white f3'>
        {`HEy ${name}, current entry count is...`}
      </div>
      <div className='white f1'>
        {`#${entry}`}
      </div>
    </div>
  );
}

export default Rank;